import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';
import {
  CompaniesOverviewModel,
  CompaniesPagination,
  CompanyCreate,
  CompanyInfo,
  CompanyInfoRoot,
  CompanyRegistrationLink,
} from '../models/company.model';
import { SignedPutUrl } from '../models/signed-put-rl.model';

export const getCompaniesOverview =
  async (): Promise<CompaniesOverviewModel> => {
    const data: AxiosResponse<CompaniesOverviewModel> = await instance
      .get(API_ROUTES.companiesOverview)
      .catch((error) => {
        throw error;
      });

    return data.data;
  };

export const getCompaniesPagination = async (
  page: number,
  searchQuery?: string,
): Promise<CompaniesPagination> => {
  const data: AxiosResponse<CompaniesPagination> = await instance
    .get(
      API_ROUTES.companies + `?page=${page}&searchQuery=${searchQuery || ''}`,
    )
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createCompany = async (
  payload: CompanyCreate,
): Promise<CompanyInfo> => {
  const data: AxiosResponse<CompanyInfoRoot> = await instance
    .post(API_ROUTES.companies, payload)
    .catch((error) => {
      throw error;
    });

  return data.data?.company;
};

export const getCompanyDetail = async (
  companyId: string,
): Promise<{ company: CompanyInfo }> => {
  const data: AxiosResponse<{ company: CompanyInfo }> = await instance
    .get(API_ROUTES.company(companyId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getCompanyRegistrationLinks = async (
  companyId: string,
): Promise<CompanyRegistrationLink[]> => {
  const data: AxiosResponse<{ registrationLinks: CompanyRegistrationLink[] }> =
    await instance.get(API_ROUTES.companyLinks(companyId)).catch((error) => {
      throw error;
    });

  return data.data.registrationLinks;
};

export const getCompaniesSearch = async (
  query: string,
): Promise<{ companyId: string; name: string }[]> => {
  const data: AxiosResponse<{
    companies: { companyId: string; name: string }[];
  }> = await instance
    .get(API_ROUTES.companiesSearch + `?query=${query}`)
    .catch((error) => {
      throw error;
    });
  return data.data.companies;
};

export const updateCompany = async (
  companyId: string,
  payload: CompanyInfo,
): Promise<CompanyInfo> => {
  const data: AxiosResponse<CompanyInfo> = await instance
    .patch(API_ROUTES.company(companyId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getSignedPutUrl = async (
  companyId: string,
  contentType: string,
  isActive: boolean,
): Promise<SignedPutUrl> => {
  const data: AxiosResponse<SignedPutUrl> = await instance
    .get(
      API_ROUTES.company(companyId) +
        `/logo?contentType=${contentType}&isActive=${isActive}`,
    )
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const uploadLogoCompanyToAWS = async (
  signedPutUrl: string,
  file: File,
) => {
  const config: AxiosRequestConfig = {
    method: 'put',
    url: signedPutUrl,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  const data = await axios(config);
  return data.data;
};

export const deleteCompany = async (companyId: string) => {
  const data: AxiosResponse<any> = await instance
    .delete(API_ROUTES.deleteCompany(companyId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getCompanyLinkNonEmployeeSponsoredAccount = async (
  companyId: string,
): Promise<CompanyRegistrationLink[]> => {
  const data: AxiosResponse<{ registrationLinks: CompanyRegistrationLink[] }> =
    await instance
      .get(API_ROUTES.companyLinkNonEmployeeSponsoredAccount(companyId))
      .catch((error) => {
        throw error;
      });

  return data.data.registrationLinks;
};

export const getAllCompanies = async (): Promise<CompaniesPagination> => {
  const data: AxiosResponse<CompaniesPagination> = await instance
    .get(API_ROUTES.allCompanies)
    .catch((error) => {
      throw error;
    });

  return data.data;
};
