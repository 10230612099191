import { getTemplateTaskListById } from 'data/api/template-task-list.api';
import { ITemplateTaskListForm } from 'data/models/template-task-list.model';
import { Box } from '@mui/material';
import { DASHBOARD_ROUTES } from 'Routes';
import { AxiosError } from 'axios';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import * as React from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatterToCaps } from 'utils';
import { useDispatch } from 'react-redux';

type ContextType = { templateTaskList: ITemplateTaskListForm | null };

export function useTemplateTaskList() {
  return useOutletContext<ContextType>();
}

export const TemplateTaskListLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { templateTaskListId } = useParams();
  const [templateTaskList, setTemplateTaskList] =
    React.useState<ITemplateTaskListForm>();
  const [isloading, setIsloading] = React.useState<boolean>(false);
  const isCreateNew = location.pathname === DASHBOARD_ROUTES.NEW_TASK_LIST;
  if (!isCreateNew) {
    if (!templateTaskListId) {
      toast.error('Invalid template task list id');
      navigate(DASHBOARD_ROUTES.LIST_TASK_LIST);
      return null;
    }
  }
  const fetchData = async () => {
    try {
      setIsloading(true);
      const result = await getTemplateTaskListById(
        templateTaskListId as string,
      );
      if (!result?.success) {
        throw new Error(result?.message);
      }
      setTemplateTaskList(result?.data);
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(
        formatterToCaps(error.message) || 'Get Template Task List info failed!',
      );
      console.error('Error: ', error);
    } finally {
      setIsloading(false);
    }
  };

  React.useEffect(() => {
    if (!isCreateNew) {
      fetchData();
    }
  }, []);

  const title = isCreateNew
    ? { title: 'Template Task List' }
    : {
        title: templateTaskList?.templateName
          ? templateTaskList?.templateName
          : '',
        subtitle: 'Template Task List Update',
        hasBack: true,
        backLink: -1,
      };
  const tabs = isCreateNew
    ? [
        {
          title: 'All Template Task List',
          selected: false,
          link: DASHBOARD_ROUTES.ROUTE_TASK_LIST,
        },
        {
          title: 'Create New',
          selected: true,
          link: DASHBOARD_ROUTES.NEW_TASK_LIST,
        },
      ]
    : [
        {
          title: 'Detail',
          selected: true,
          link: `${DASHBOARD_ROUTES.EDIT_TASK_LIST}`.replace(
            ':templateTaskListId',
            `${templateTaskListId}`,
          ),
        },
        {
          title: 'Template Task',
          selected: false,
          link: `${DASHBOARD_ROUTES.LIST_TASK}`.replace(
            ':templateTaskListId',
            `${templateTaskListId}`,
          ),
        },
        {
          title: 'Create Template Task',
          selected: false,
          link: `${DASHBOARD_ROUTES.NEW_TASK}`.replace(
            ':templateTaskListId',
            `${templateTaskListId}`,
          ),
        },
      ];
  if (isloading) return null;
  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <Box sx={{ marginTop: '7px' }}>
        <div className="nv-container">
          <Outlet context={{ templateTaskList }} />
        </div>
      </Box>
    </>
  );
};
