import { IPager } from './common.model';

export interface ITemplateTaskList {
  id?: string;
  audience?: string;
  relatedRoleRelationship?: string;
  eventType?: string;
  eventName?: string;
  deathDateRange?: string;
  navigationRole?: string;
  includeDueDates?: boolean;
  templateName?: string;
}

export interface ITemplateTaskListForm {
  id?: string;
  audience?: string;
  relatedRoleRelationship?: string;
  eventType?: string;
  eventName?: string;
  deathDateRange?: string;
  navigationRole?: string;
  includeDueDates?: boolean;
  templateName?: string;
  companyId?: string;
  companies?: string;

  companyTemplateTaskLists?: any;
}

export interface ITemplateTaskListRoot {
  templateTaskList: ITemplateTaskList[];
  pager: IPager;
}

export enum UserEventTypeCompanyEnum {
  SomeoneHasHadAChangeInHealth = "I'm grieving for someone who has had a change in health",
  SomeoneWithTerminalIllness = "I'm caring for someone with a terminal illness",
  GrievingDeath = "I'm grieving a death",
  GrievingPregnancyLoss = "I'm grieving a pregnancy loss",
  GrievingOrHasExperiencedLoss = "I'm supporting someone who is grieving or has experienced loss",
  WorkInJobWhereGriefAndLossIsFrequent = 'I work in a job where grief and loss is frequent',
  GrievingDivorceOrBreakUp = "I'm grieving a divorce or break-up",
  GrievingJobLoss = "I'm grieving a job loss",
  HereForAnotherReason = "I'm here for another reason",
}

export enum RelationshipType {
  Mother = 'Mother',
  Father = 'Father',
  Parent = 'Parent',
  Guardian = 'Guardian',
  Child = 'Child',
  Sibling = 'Sibling',
  Aunt = 'Aunt',
  Uncle = 'Uncle',
  Niece = 'Niece',
  Nephew = 'Nephew',
  Cousin = 'Cousin',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Grandparent = 'Grandparent',
  Wife = 'Wife',
  Husband = 'Husband',
  Spouse = 'Spouse',
  Domestic_Partner = 'Domestic Partner',
  Boyfriend = 'Boyfriend',
  Girlfriend = 'Girlfriend',
  Friend = 'Friend',
  Manager = 'Manager',
  Employee = 'Employee',
  Direct_Report = 'Direct Report',
  Co_worker = 'Co-worker',
  Pet = 'Pet',
  Unknown = 'Unknown',
}
