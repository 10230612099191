import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import Select from 'components/Select';
import Button from 'components/Button';
import CheckboxSingle from 'components/CheckboxSingle';
import TextField from 'components/TextField';
import Textarea from 'components/Textarea';
import ModalCustom from 'components/Modal';
import {
  createCompany,
  deleteCompany,
  getCompanyLinkNonEmployeeSponsoredAccount,
  getSignedPutUrl,
  updateCompany,
  uploadLogoCompanyToAWS,
} from 'data/api/company.api';
import { DASHBOARD_ROUTES } from 'Routes';
import {
  CompanyInfo,
  CompanyRegistrationLink,
} from '@data/models/company.model';
import { useDispatch, useSelector } from 'react-redux';
import { saveCompany, selectCompany } from 'store/companySlide';
import { STATES } from 'data/constants/static';
import { CompanyType } from 'pages/company/new-company/interface';
import { SignedPutUrl } from 'data/models/signed-put-rl.model';
import TextFieldNumber from 'components/TextFieldNumber';

export const DetailFormCompany = () => {
  const [modalDeleteDialog, setModalDeleteDialog] = useState(false);
  const [modalActiveDialog, setModalActiveDialog] = useState(false);
  const [value, setValue] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(true);

  const [file, setFile] = useState<File>();
  const dispatch = useDispatch();
  const companyInfo: CompanyInfo = useSelector(selectCompany);
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(
    companyInfo.logo,
  );
  const { company_id } = useParams();
  const { handleSubmit, reset, watch, control, clearErrors } = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate();

  const [links, setLinks] = useState<CompanyRegistrationLink[]>();

  const fetchLinks = async (companyId?: string) => {
    try {
      if (!companyId) return;
      setLinks(await getCompanyLinkNonEmployeeSponsoredAccount(companyId));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchLinks(company_id);
  }, [company_id]);

  const handleUploadLogo = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      const fileCurrent = files[0];
      const ACCEPT_TYPES = ['image/png', 'image/jpeg'];
      if (!ACCEPT_TYPES.includes(fileCurrent.type)) {
        toast.error(
          'This file type is not supported. The following file types are supported: jpeg, png!',
        );
        return;
      }
      const FILE_MAX_SIZES = 5;
      const isLimitSize = fileCurrent.size / 1024 / 1024 < FILE_MAX_SIZES;
      if (!isLimitSize) {
        toast.error(
          `The file size is too large. The maximum file size is ${FILE_MAX_SIZES}MB.`,
        );
        return;
      }
      try {
        if (company_id) {
          const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
            company_id,
            fileCurrent.type,
            isActive,
          );
          await uploadLogoCompanyToAWS(dataSignedPut.signedPutUrl, fileCurrent);
          if (companyInfo.logo) {
            const parsedCompanyLogo = new URL(companyInfo.logo);
            const origin = parsedCompanyLogo?.origin;
            const updatedUrl = `${origin}/${dataSignedPut.key}`;
            setPhotoUrl(updatedUrl);
          }
          toast.success('Upload success!');
        } else {
          setPhotoUrl(URL.createObjectURL(fileCurrent));
          setFile(fileCurrent);
        }
      } catch (error) {
        console.error(error);
        toast.error('Upload fail!');
      }
    }
  };

  const uploadLogoForCreateCompanyToAws = async (
    companyId?: string,
    isActive?: boolean,
  ) => {
    if (companyId && file) {
      const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
        companyId,
        file.type,
        isActive || false,
      );
      setPhotoUrl(dataSignedPut.userPhotoUrl);
      await uploadLogoCompanyToAWS(dataSignedPut.signedPutUrl, file);
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleCreateCompany = async () => {
    const {
      name,
      policyDescription,
      defaultState,
      slug,
      companyType,
      address1,
      address2,
      city,
      zipCode,
      generalEmail,
      logo,
    } = watch();
    try {
      if (!isActive) {
        setValue(true);
        setModalActiveDialog(true);
      }
      if (isActive) {
        if (!company_id) {
          await createCompany({
            name,
            policyDescription,
            defaultState,
            slug,
            companyType,
            address1,
            address2,
            city,
            zipCode,
            generalEmail,
            logo,
            isActive: isActive,
          })
            .then((data: CompanyInfo) => {
              if (data && file) {
                uploadLogoForCreateCompanyToAws(data.companyId, isActive);
              }
              toast.success('Create company successfully!');
              dispatch(saveCompany(data));
              navigate(DASHBOARD_ROUTES.COMPANY_LIST);
            })
            .catch((error) => {
              console.error(error);
              toast.error('Create company fail!');
            });
        } else {
          await updateCompany(company_id, {
            companyId: company_id,
            name,
            policyDescription,
            defaultState,
            slug,
            companyType,
            address1,
            address2,
            city,
            zipCode,
            generalEmail,
            logo,
            isActive: isActive,
          })
            .then((data) => {
              toast.success('Update company successfully!');
              dispatch(saveCompany(data));
              navigate(DASHBOARD_ROUTES.COMPANY_LIST);
            })
            .catch((error) => {
              console.error(error);
              toast.error('Update company fail!');
            });
        }
      }
    } catch (error) {
      toast.error('error');
    }
  };

  const handleDeleteCompany = async () => {
    try {
      if (company_id) {
        await deleteCompany(company_id).then(() => {
          toast.success('Delete company successfully!');
          navigate(DASHBOARD_ROUTES.COMPANY_LIST);
        });
      }
    } catch (error) {
      toast.error('error');
    }
  };

  const resetForm = () => {
    reset({
      companyId: companyInfo?.companyId || '',
      name: companyInfo?.name || '',
      companyType: companyInfo?.companyType || '',
      address1: companyInfo?.address1 || '',
      address2: companyInfo?.address2 || '',
      city: companyInfo?.city || '',
      defaultState: companyInfo?.defaultState || '',
      zipCode: companyInfo?.zipCode || '',
      generalEmail: companyInfo?.generalEmail || '',
      policyDescription: companyInfo?.policyDescription || '',
      registrationLink: companyInfo?.registrationLink || '',
      logo: companyInfo?.logo || photoUrl || '',
      isActive: companyInfo?.isActive || true,
      slug: companyInfo?.slug || '',
    });
    clearErrors();
  };

  React.useEffect(() => {
    resetForm();
    setPhotoUrl(companyInfo.logo);
    setIsActive(companyInfo.isActive || false);
  }, [companyInfo]);

  const handleSubmitDelete = () => {
    setModalDeleteDialog(false);
    handleDeleteCompany();
  };

  const handleCancelActive = () => {
    setModalActiveDialog(false);
    setIsActive(true);
  };

  const handleSubmitSave = async () => {
    setModalActiveDialog(false);
    setIsActive(false);
    if (value) {
      const {
        name,
        policyDescription,
        defaultState,
        slug,
        companyType,
        address1,
        address2,
        city,
        zipCode,
        generalEmail,
        logo,
      } = watch();
      try {
        if (!company_id) {
          await createCompany({
            name,
            policyDescription,
            defaultState,
            slug,
            companyType,
            address1,
            address2,
            city,
            zipCode,
            generalEmail,
            logo,
            isActive: isActive,
          })
            .then((data: CompanyInfo) => {
              if (data && file) {
                uploadLogoForCreateCompanyToAws(data.companyId, isActive);
              }
              toast.success('Create company successfully!');
              dispatch(saveCompany(data));
              navigate(DASHBOARD_ROUTES.COMPANY_LIST);
            })
            .catch((error) => {
              console.error(error);
              toast.error('Create company fail!');
            });
        } else {
          await updateCompany(company_id, {
            companyId: company_id,
            name,
            policyDescription,
            defaultState,
            slug,
            companyType,
            address1,
            address2,
            city,
            zipCode,
            generalEmail,
            logo,
            isActive: isActive,
          })
            .then((data) => {
              toast.success('Update company successfully!');
              dispatch(saveCompany(data));
              navigate(DASHBOARD_ROUTES.COMPANY_LIST);
            })
            .catch((error) => {
              console.error(error);
              toast.error('Update company fail!');
            });
        }
      } catch (error) {
        toast.error('error');
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        width: '100%',
      }}
    >
      <form onSubmit={handleSubmit(handleCreateCompany)} className="w-full">
        <Box
          sx={{
            order: { xs: 3, md: 1 },
            width: '63%',
            display: 'flex',
            marginTop: '24px',
            justifyContent: 'end',
          }}
        >
          <Button
            sx={{
              border: '1px solid #213B54',
              color: '#213B54',
              fontWeight: 700,
              display: company_id ? '' : 'none',
              padding: '10px 20px',
              width: '104px',
            }}
            onClick={() => {
              setModalDeleteDialog(true);
            }}
          >
            Delete
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 700,
              marginLeft: '8px',
              width: '104px',
              padding: '10px 30px ',
            }}
          >
            Save
          </Button>
        </Box>
        <Grid
          container
          sx={{
            marginY: '24px',
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            gap={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <Box
              sx={{
                width: '86%',
                display: 'grid',
                gap: 2,
              }}
            >
              <TextField
                label="Name"
                name="name"
                control={control}
                required
                placeholder="Name"
                rules={{
                  validate: (value) => {
                    const regex = /([\w\\.\-\\']\s*){2,50}$/;
                    if (!value) return true;
                    if (value?.length < 2 || regex.test(value) === false)
                      return 'Please enter 2 ~ 50 letters of a-z, A-Z, 0-9, Period, Single quote, Whitespace, or Hyphen';
                    return true;
                  },
                  required: 'Name is required.',
                }}
              />
              <Select
                data={[
                  {
                    value: CompanyType.Employer,
                    label: CompanyType.Employer,
                  },
                  {
                    value: CompanyType.Hospice,
                    label: CompanyType.Hospice,
                  },
                  {
                    value: CompanyType.LongtermCare,
                    label: CompanyType.LongtermCare,
                  },
                  {
                    value: CompanyType.DeathCare,
                    label: CompanyType.DeathCare,
                  },
                  {
                    value: CompanyType.HealthSystem,
                    label: CompanyType.HealthSystem,
                  },
                  {
                    value: CompanyType.HealthPlan,
                    label: CompanyType.HealthPlan,
                  },
                ]}
                control={control}
                showSelected={true}
                label="Company Type"
                name="companyType"
              />
              <TextField
                label="Address 1"
                name="address1"
                control={control}
                placeholder="Address 1"
              />
              <TextField
                label="Address 2"
                name="address2"
                control={control}
                placeholder="Address 2"
              />
              <TextField
                label="City"
                control={control}
                name="city"
                placeholder="City"
              />
              <Select
                data={STATES.filter((state) => {
                  return state.name != 'All';
                }).map((item) => ({
                  value: item.abbreviation,
                  label: item.name,
                }))}
                control={control}
                showSelected={true}
                label="State"
                name="defaultState"
              />
              <TextFieldNumber
                autoComplete="off"
                name="zipCode"
                label="Zip Code"
                control={control}
                inputProps={{
                  maxLength: 5,
                }}
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    if (value?.length !== 5)
                      return 'The US zip code must contain 5 digits.';
                    return true;
                  },
                }}
              />
              <TextField
                label="General email"
                control={control}
                name="generalEmail"
                placeholder="General Email"
                type="email"
              />
              <Textarea
                rows={10}
                placeholder={`Bereavement program/policy`}
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) =>
                  (e.target.placeholder = `Bereavement program/policy`)
                }
                sx={(theme) => ({
                  fieldset: {
                    borderColor: `${theme.palette.primary.main}`,
                  },
                  marginRight: '16px',
                })}
                name="policyDescription"
                label="Bereavement program/policy"
                control={control}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                  color: '#000000',
                }}
              >
                {`https://app.betterleave.com/`}
                <TextField
                  label="Landing Page URL"
                  name="slug"
                  sx={{
                    marginLeft: '4px',
                    width: '86%',
                  }}
                  control={control}
                  placeholder="Landing Page URL"
                />
              </Box>
              {company_id && (
                <TextField
                  label="Registration link"
                  sx={{
                    marginBottom: '48px',
                    width: '100%',
                  }}
                  name="registrationLink"
                  placeholder="Registration link"
                  inputProps={{
                    onClick: () =>
                      links?.length !== 0 &&
                      window.open(
                        links ? links[0]?.url : '',
                        '_blank',
                        'noopener,noreferrer',
                      ),
                  }}
                  value={links ? links[0]?.url : ''}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            <Stack
              spacing={2}
              sx={{
                width: '27%',
              }}
            >
              <Box
                sx={{
                  order: { xs: 2, md: 2 },
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                  color: '#000000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <CheckboxSingle
                  name="isActive"
                  label="Active"
                  checked={isActive}
                  onChange={(event) => {
                    setIsActive(event.target.checked);
                    setValue(false);
                  }}
                />
              </Box>
              <Box
                sx={{
                  order: { xs: 1, md: 3 },
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                    color: '#000000',
                  }}
                >
                  Company Logo
                </Typography>
                <Button
                  component="label"
                  sx={{
                    width: '220px',
                    height: '150px !important',
                    color: '#000000 !important',
                    background: '#D9D9D97A !important',
                    border: '2px solid #e9ebef',
                    borderRadius: '4px !important',
                    cursor: 'pointer',
                    margin: '3px 0 0 ',
                    textAlign: 'center',
                    fontWeight: 500,
                  }}
                >
                  {photoUrl ? (
                    <img
                      key={Date.now()}
                      src={`${photoUrl}`}
                      style={{
                        display: 'block',
                        height: '150px',
                        width: '220px',
                        objectFit: 'contain',
                      }}
                    />
                  ) : (
                    <>Upload file</>
                  )}

                  <VisuallyHiddenInput
                    onChange={handleUploadLogo}
                    name="photoUrl"
                    type="file"
                  />
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <ModalCustom
        title={'Are you sure you want to delete this Company?'}
        modal={modalDeleteDialog}
        subTittle={
          'Deleting this company will delete all related users and notes tied to this company. '
        }
        handleClose={() => setModalDeleteDialog(false)}
        groupButton={
          <>
            <Button
              onClick={() => setModalDeleteDialog(false)}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                width: '97px',
                height: '40px',
                lineHeight: '20px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitDelete}
              variant="outlined"
              sx={{
                color: '#B3261E',
                fontWeight: 700,
                fontSize: '14px',
                width: '97px',
                height: '40px',
                lineHeight: '20px',
                border: '1px solid #213B54',
              }}
            >
              Delete
            </Button>
          </>
        }
      />
      <ModalCustom
        title={'Are you sure you want to deactivate this Company?'}
        modal={modalActiveDialog}
        subTittle={
          'Deactivating this company will  deactivate landing pages and registration links tied to this company. Users tied to this company will NOT be deactivated - you will need to do this manually. '
        }
        handleClose={handleCancelActive}
        groupButton={
          <>
            <Button
              onClick={handleCancelActive}
              variant="outlined"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px',
                border: '1px solid #213B54',
                width: '97px',
                height: '40px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitSave}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px',
                width: '97px',
                height: '40px',
              }}
            >
              Save
            </Button>
          </>
        }
      />
    </Box>
  );
};
