/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Tooltip,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';

export interface ISelectOption {
  value: any;
  label: string;
}
type TSelectProps = MuiSelectProps & {
  data: ISelectOption[];
  label?: string;
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  labelClassName?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: ReactNode;
  customSx?: any;
  displayEmpty?: boolean;
  value?: any;
  fullHeight?: any;
};
const CustomSelect = ({
  data,
  label = '',
  labelClassName = '!bg-white !px-2 !-ml-2',
  error = false,
  helperText = '',
  value = [],
  customSx = {},
  displayEmpty = false,
  ...props
}: TSelectProps) => {
  return (
    <FormControl
      sx={{
        width: props.fullWidth ? '100%' : 150,
        ...customSx?.form,
      }}
      error={error}
      size={props.size}
    >
      <InputLabel
        shrink={value?.length > 0 ? true : false}
        className={labelClassName}
        required={props.required}
        sx={{
          ...customSx?.label,
          '& .MuiFormLabel-asterisk': {
            color: '#DC362E',
          },
          color: props.disabled ? '#1D1B205D' : '',
          top: value?.length > 0 ? '0px' : displayEmpty ? '6px' : '-3px',
        }}
      >
        {label}
      </InputLabel>

      <MuiSelect
        sx={{
          color: value ? '#1D1B20' : '#1D1B205D',
          height: props.fullHeight ? '56px' : '32px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error
              ? '#B3261E !important'
              : `${
                  customSx?.select?.color ? customSx?.select?.color : '#79747E'
                } !important`,
            ...customSx?.select,
          },
          '& .MuiSelect-icon': {
            color: '#1D1B20',
          },
          fieldset: {
            top: value?.length > 0 ? '-5px' : '0px',
            legend: {
              display: value?.length > 0 ? 'block' : 'none',
            },
          },
        }}
        multiple
        value={value}
        renderValue={(selected) => {
          // if (displayEmpty) {
          //   if (!selected || !(selected as string[]).length) {
          //     return <span>-- Select --</span>;
          //   }
          // }

          return (
            <>
              <Tooltip
                title={(selected as string[]).filter((str) => str).join(', ')}
              >
                <span>
                  {(selected as string[]).filter((str) => str).join(', ')}
                </span>
              </Tooltip>
            </>
          );
        }}
        {...props}
        inputProps={{
          className: props.disabled ? '!cursor-not-allowed' : '',
        }}
        displayEmpty={displayEmpty}
      >
        {data.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={
                  (value as string[]).indexOf(option.value) > -1 ? true : false
                }
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MultiSelectTracking = ({
  control,
  name,
  rules = {},
  ...props
}: TSelectProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue || []}
        render={({ field: { ref, onChange, ...restField }, fieldState }) => {
          const value =
            restField.value !== undefined
              ? restField.value
              : props.defaultValue;
          let previousValue = [...value];
          const wrapperOnChange = (vals: any) => {
            const values = vals.target.value || [];
            if (!props.data.find((f) => f.value === '')) {
              onChange(vals);
              return;
            }
            if (!values.includes('')) {
              if (previousValue.includes('')) {
                vals.target.value = [];
                previousValue = [];
                onChange(vals);
              } else {
                const diff = props.data.filter(
                  (f) => f.value && !values.includes(f.value),
                );
                if (!diff.length) {
                  vals.target.value.push('');
                }
                previousValue = [...vals.target.value];
                onChange(vals);
              }
            } else {
              if (previousValue.includes('')) {
                if (values.includes('')) {
                  vals.target.value = values.filter((f: string) => f);
                  previousValue = [...vals.target.value];
                  onChange(vals);
                  return;
                }
              } else {
                vals.target.value = props.data.map((f) => f.value);
                previousValue = [...vals.target.value];
                onChange(vals);
                return;
              }
            }
          };
          return (
            <>
              <CustomSelect
                {...restField}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...props}
                value={value}
                onChange={wrapperOnChange}
                input={<OutlinedInput label={props.label} />}
                displayEmpty={props.displayEmpty}
              />
            </>
          );
        }}
      />
    );
  }
  return <CustomSelect {...props} />;
};

export default MultiSelectTracking;
