import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import { saveUser } from 'store/userSlide';
import { SearchTracking } from 'components/SearchTracking';
import { useForm } from 'react-hook-form';
import MultiSelectTracking from 'components/MultiSelectTracking';
import DatePickerTracking from 'components/DatePickerTracking';
import CheckIcon from 'assets/icon-components/checkIcon';
import { User, UsersPagination } from '@data/models/user.model';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from 'Routes';
import { IListRequest } from '@data/models/common.model';
import { getAllUsers, getCompanyUsers } from 'data/api/company-employee.api';
import { getAllCompanies } from 'data/api/company.api';
import { CompaniesPagination, Company } from '@data/models/company.model';
import { saveNavigateLinkHeader } from 'store/navigateSlide';

const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

const FilterBox = ({ control, label, name, data, fullWidth }: any) => {
  return (
    <MultiSelectTracking
      name={name}
      label={label}
      control={control}
      data={data}
      size="small"
      fullWidth={fullWidth}
      variant="outlined"
      customSx={{
        form: { height: '32px !important' },
        label: {
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '20px',
        },
        select: {
          border: '1px solid #213B54',
          borderRadius: '10px',
          color: '#213B54',
        },
      }}
    />
  );
};

const columns: GridColDef[] = [
  {
    field: 'is_active',
    headerName: 'Active',
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      return <>{params.row.active && <CheckIcon />}</>;
    },
  },
  {
    field: 'is_admin',
    headerName: 'Admin',
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      return <>{params.row.isAdmin && <CheckIcon />}</>;
    },
  },
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {params.row.companyName}
        </Typography>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {params.row.name}
        </Typography>
      );
    },
  },
  {
    field: 'emails',
    headerName: 'Email',
    flex: 1,
    minWidth: 300,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {params.row.email}
        </Typography>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {formatPhoneNumber(params.row.phone)}
        </Typography>
      );
    },
  },
  {
    field: 'login_count',
    headerName: 'Total Logins',
    flex: 1,
    sortable: true,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {params.row.loginCount}
        </Typography>
      );
    },
  },
  {
    field: 'last_login_date',
    headerName: 'Last Login Date',
    flex: 1,
    minWidth: 180,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.value || params.row.lastLoginDate)
        return moment
          .utc(params.row.value || params.row.lastLoginDate)
          .local()
          .format('MM/DD/YYYY, hh:mm A');
      return '';
    },
  },
  {
    field: 'created_date',
    headerName: 'Date Added',
    flex: 1,
    minWidth: 150,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return moment
        .utc(params.row.createdDate)
        .local()
        .format('MM/DD/YYYY, hh:mm A');
    },
  },
];

export const UserListNew = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [pager, setPager] = useState<any>();
  const [filter, setFilter] = useState<IListRequest>({
    order: 'desc',
    page: 0,
    pageSize: 50,
    sortBy: undefined,
  });

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
  });

  const fetchCompaniesList = async () => {
    try {
      const data: CompaniesPagination = await getAllCompanies();
      setCompanies(data.companies);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsersList = async (values?: any) => {
    try {
      const request = {
        isActive: values.isActive,
        isAdmin: values.isAdmin,
        startDate:
          values?.startDate && moment(values?.startDate).isValid()
            ? moment(values?.startDate).startOf('day').toString()
            : undefined,
        endDate:
          values?.endDate && moment(values?.endDate).isValid()
            ? moment(values?.endDate).endOf('day').toString()
            : undefined,
        companies: values.companies,
      };

      const response: UsersPagination = await getAllUsers({
        ...filter,
        ...request,
      });

      setUsers(response.users);
      setPager(response.pager);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(saveUser(params.row));
    const fromLink = DASHBOARD_ROUTES.ROUTE_USER_LIST;
    const toLink = DASHBOARD_ROUTES.ROUTE_USER_DETAIL + params.row.userId;
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  React.useEffect(() => {
    handleSubmit(fetchUsersList)();
  }, [filter]);

  React.useEffect(() => {
    fetchCompaniesList();
  }, []);

  React.useEffect(() => {
    const subscription = watch(
      async () => await handleSubmit(fetchUsersList)(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'All Users',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
        ]}
      />
      <div className="nv-container">
        <div className="section employees mb-6">
          <Stack sx={{ marginTop: '10px' }} spacing={2} width={'100%'}>
            <Stack
              sx={{
                overflowX: 'auto',
                overflowY: 'hidden',
                [theme.breakpoints.up(1480)]: {
                  flexDirection: 'row',
                  alignItems: 'center',
                },
                [theme.breakpoints.down(1480)]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                },
              }}
              paddingBottom={'8px'}
              width={'100%'}
            >
              <Stack
                direction={{ xs: 'column', xxl: 'row' }}
                spacing={2}
                alignItems={{ xs: 'start', xxl: 'center' }}
                width={'100%'}
              >
                <SearchTracking
                  label="Search for a user"
                  onChange={(title: string) => {
                    setFilter({ ...filter, query: title });
                  }}
                  debounce={300}
                />
                <Stack spacing={2} direction={'row'}>
                  <FilterBox
                    name="companies"
                    label="Company"
                    control={control}
                    data={
                      companies
                        ? companies.map((item: Company) => {
                            return {
                              value: item.name,
                              label: item.name,
                            };
                          })
                        : []
                    }
                  />
                  <FilterBox
                    name="isActive"
                    label="Is Active"
                    control={control}
                    data={[
                      {
                        value: 'Yes',
                        label: 'Yes',
                      },
                      {
                        value: 'No',
                        label: 'No',
                      },
                    ]}
                  />
                  <FilterBox
                    name="isAdmin"
                    label="Is Admin"
                    control={control}
                    data={[
                      {
                        value: 'Yes',
                        label: 'Yes',
                      },
                      {
                        value: 'No',
                        label: 'No',
                      },
                    ]}
                  />
                  <DatePickerTracking
                    name="startDate"
                    label="FROM Last Login Date"
                    control={control}
                    inputProps="!text-sm !h-[32px] !rounded-[10px]"
                  />
                  <DatePickerTracking
                    name="endDate"
                    label="TO Last Login Date"
                    control={control}
                    inputProps="!text-sm !h-[32px] !rounded-[10px]"
                  />
                </Stack>
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: '15px',
                lineHeight: '24px',
                fontWeight: 500,
                letterSpacing: '0.5px',
              }}
            >
              {pager ? pager?.total : 0} records
            </Typography>
            <Table
              getRowId={(r) => r.userId}
              loading={status === 'loading'}
              rows={users && users ? users : []}
              columns={columns}
              paginationMode="server"
              sortingMode="server"
              disableColumnFilter={true}
              disableColumnMenu={true}
              filter={filter}
              rowCount={pager?.total || 0}
              onFilterChange={setFilter}
              pageSizeOptions={[10, 25, 50, 100]}
              onRowClick={handleRowClick}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  outline: 'none !important',
                  backgroundColor: '#F8F9FB',
                  maxHeight: '47px !important',
                  minHeight: '47px !important',
                  borderWidth: '1px 0px 1px 0px',
                  borderStyle: 'solid',
                  borderColor: '#DBD8D8',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#213B54',
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                },
                '& .MuiDataGrid-cell': {
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: '12px !important',
                  cursor: 'pointer',
                },
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};
