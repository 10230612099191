import React from 'react';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { createUserNote } from '../../../../data/api/user-note.api';
import { Box, Stack, Typography } from '@mui/material';

const schema = yup
  .object({
    note: yup.string().required('Note is required.'),
  })
  .required();

const UserNoteAdd = () => {
  const { user_id } = useParams();
  const [isSubmitting, setIsSubmitting] = React.useState(true);
  const { register, formState, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;
  const navigate = useNavigate();

  const handleCreateUserNote = async () => {
    if (isSubmitting && user_id) {
      setIsSubmitting(false);

      const { note } = getValues();
      await createUserNote(user_id, { note })
        .then(() => {
          toast.success('Create user note successfully!');
          navigate(DASHBOARD_ROUTES.USER_ID_NOTES(user_id));
        })
        .catch((error) => {
          console.error(error);
          toast.error('Create user note fail!');
        })
        .finally(() => {
          setIsSubmitting(true);
        });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography
        sx={{
          color: '#666',
          fontSize: '15px',
        }}
      >
        Leave a new internal note.
      </Typography>
      <div id="add-note">
        <Box
          sx={{
            width: '100%',
          }}
        >
          <textarea className="resize-y" {...register('note')} />
        </Box>
        <span className="text-red-100 text-sm py-2">
          {errors.note?.message}
        </span>
        <div className="buttons mt-4">
          <input
            disabled={!isValid}
            className={!isValid ? 'opacity-50 cursor-not-allowed' : ''}
            type="submit"
            value="Add Note"
            onClick={() => handleCreateUserNote()}
          />
        </div>
      </div>
    </Stack>
  );
};
export default UserNoteAdd;
