export const EducationLevel: string[] = [
  'Some high school',
  'High school',
  'Bachelor’s degree',
  'Master’s degree',
  'Ph.D. or higher',
  'Trade school',
];

export const Ethnicity: string[] = [
  'Asian',
  'Black or African descent',
  'Caucasian',
  'Hispanic',
  'Latinx',
  'Native American',
  'Pacific Islander',
  'South Asian',
  'Other Racial or Ethnic Background',
];

export const IncomeBrackets: string[] = [
  '$0 to $24,999',
  '$25,000 to $49,999',
  '$50,000 to $74,999',
  '$75,000 to $99,999',
  '$100,000 to $124,999',
  '$125,000 to $149,999',
  '$150,000 to $174,999',
  '$175,000 to $199,999',
  '$200,000 and over',
];

export enum TypeOfEmailEnum {
  Work = 'Work',
  Personal = 'Personal',
}

export enum EmploymentStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}
