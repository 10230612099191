import * as React from 'react';
import { DetailFormTaskList } from '../components/DetailFormTaskList';
import { Stack } from '@mui/material';

export const DetailTaskList = () => {
  return (
    <Stack>
      <DetailFormTaskList />
    </Stack>
  );
};
