import React, { useEffect } from 'react';
import googleImage from 'assets/temp/google.png';
import { DASHBOARD_ROUTES } from '../../Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import './Login.scss';
import {
  fetchRefreshToken,
  setSessionStorageToken,
  login,
} from '../../data/api/auth.api';
import queryString from 'query-string';
import { TokenInfo } from '../../data/models/auth.model';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';

const GOOGLE_SSO = 'sso';
export const LoginPageDev: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { token } = queryParams;

  useEffect(() => {
    const handleCheckAccount = async () => {
      const data: TokenInfo | undefined = await fetchRefreshToken();
      if (data?.access_token) {
        navigate(DASHBOARD_ROUTES.COMPANY_OVERVIEW);
      }
    };

    if (queryParams.error === GOOGLE_SSO) {
      toast.error('You are not authorized to access this page');
    }

    if (token) {
      setSessionStorageToken({
        refresh_token: token as string,
        access_token: '',
      });

      void handleCheckAccount();
    }
  }, [location, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      agreement: false,
    },
  });

  const submitHandler = async ({
    email: username,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const result: void | TokenInfo = await login({
        username,
        password,
      });

      if (result instanceof Object && result?.access_token) {
        navigate(DASHBOARD_ROUTES.HOME);
      }
    } catch (error) {
      console.error('Error: ', error);
      toast.error('Login fail');
    }
  };

  return (
    <div
      id="login"
      className="bg-[#2c3f54] w-[600px] p-[50px] text-white text-center"
    >
      <div className="title bg-no-repeat text-[28px] leading-[38px] font-normal ml-[50px] mb-[40px]">
        betterleave <span className="text-orange-100">admin</span>
      </div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-col items-start mb-6">
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            type="email"
            sx={{ backgroundColor: '#FFFFFF' }}
            fullWidth
            {...register('email', { required: 'Email is required.' })}
          />
          <span className="text-sm text-red-100">{errors.email?.message}</span>
        </div>
        <div className="flex flex-col items-start password-field">
          <TextField
            id="assword"
            label="Password"
            variant="outlined"
            fullWidth
            {...register('password', {
              required: 'Password is required.',
              minLength: { value: 8, message: 'Min length is 8' },
            })}
            type="password"
            sx={{ backgroundColor: '#FFFFFF' }}
          />
          <span className="text-sm text-red-100">
            {errors.password?.message}
          </span>
        </div>
        <div className="flex flex-col items-start">
          <button
            className="mt-7 bg-secondary text-white w-full h-10 rounded py-[10px] px-4"
            type="submit"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};
