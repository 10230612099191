import React from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import DetailFormUser from '../../components/DetailFormUser';

const NewUserNew = () => {
  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'All Users',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
          // {
          //   title: 'User Events',
          //   selected: false,
          //   link: DASHBOARD_ROUTES.ROUTE_USER_LOSS_EVENTS,
          // },
        ]}
      />
      <div className="pl-[140px] pr-[8px]">
        <DetailFormUser />
      </div>
    </>
  );
};
export default NewUserNew;
