import {
  getTemplateTaskList,
  getTemplateTaskListById,
} from 'data/api/template-task-list.api';
import { ITemplateTaskListRoot } from '@data/models/template-task-list.model';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListRequest } from '@data/models/common.model';
import { RootState } from './store';
export interface TemplateTaskListState {
  value?: any;
  status: 'idle' | 'loading' | 'failed';
  values?: ITemplateTaskListRoot;
}
const initialState: TemplateTaskListState = {
  value: undefined,
  status: 'idle',
  values: undefined,
};

export const fetchTemplateTaskListAsync = createAsyncThunk(
  'admin/fetchTemplateTaskListStatus',
  async (params: IListRequest) => {
    const response = await getTemplateTaskList(params);
    return response;
  },
);
export const fetchTemplateTaskListIdAsync = createAsyncThunk(
  'admin/fetchTemplateTaskListStatusId',
  async (params: string) => {
    const response = await getTemplateTaskListById(params);
    return response;
  },
);

export const templateTaskListSlice = createSlice({
  name: 'templateTaskList',
  initialState,
  reducers: {
    setTemplateTaskListSlice: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTemplateTaskListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplateTaskListAsync.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchTemplateTaskListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTemplateTaskListIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplateTaskListIdAsync.fulfilled, (state, action) => {
        state.value = action.payload.data;
        state.status = 'idle';
      })
      .addCase(fetchTemplateTaskListIdAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});
export const { setTemplateTaskListSlice } = templateTaskListSlice.actions;
export const selectTemplateTaskLists = (state: RootState) =>
  state.templateTaskList.values;
export const selectTemplateTaskList = (state: RootState) =>
  state.templateTaskList.value;
export default templateTaskListSlice.reducer;
