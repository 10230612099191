import { getTemplateTaskById } from 'data/api/template-task.api';
import { ITemplateTask } from 'data/models/template-task.model';
import { Box } from '@mui/material';
import { DASHBOARD_ROUTES } from 'Routes';
import { AxiosError } from 'axios';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import * as React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatterToCaps } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTemplateTaskListIdAsync,
  selectTemplateTaskList,
} from 'store/templateTaskListSlide';
type ContextType = { templateTask: ITemplateTask | null };
export function useTemplateTask() {
  return useOutletContext<ContextType>();
}
export const TemplateTaskLayout = () => {
  const { templateTaskId, templateTaskListId } = useParams();
  const [templateTask, setTemplateTask] = React.useState<ITemplateTask>();
  const [isloading, setIsloading] = React.useState<boolean>(false);
  const templateTaskList = useSelector(selectTemplateTaskList);
  const dispatch = useDispatch();
  const fetchTemplateTaskList = async () => {
    try {
      dispatch(fetchTemplateTaskListIdAsync(templateTaskListId ?? ''));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      setIsloading(true);
      if (templateTaskId) {
        const result = await getTemplateTaskById(templateTaskId as string);
        if (!result?.success) {
          throw new Error(result?.message);
        }
        dispatch(
          fetchTemplateTaskListIdAsync(result.data?.templateTaskListsId ?? ''),
        );
        setTemplateTask(result?.data);
      }
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(
        formatterToCaps(error.message) || 'Get template task info failed!',
      );
      console.error('Error: ', error);
    } finally {
      setIsloading(false);
    }
  };

  React.useEffect(() => {
    if (templateTaskListId) {
      fetchTemplateTaskList();
    } else if (templateTaskId) {
      fetchData();
    }
  }, [templateTaskListId, templateTaskId]);

  const title = templateTaskId
    ? {
        title: templateTask?.taskName ? templateTask?.taskName : '',
        subtitle: 'Template Task Update',
        hasBack: true,
        backLink: -1,
      }
    : {
        title: templateTaskList?.templateName ?? '',
        subtitle: 'Create Template Task',
        hasBack: true,
        backLink: -1,
      };

  const tabs = templateTaskId
    ? [
        {
          title: 'Detail',
          selected: true,
          link: `${DASHBOARD_ROUTES.EDIT_TASK}`.replace(
            ':templateTaskId',
            `${templateTaskId}`,
          ),
        },
      ]
    : [
        {
          title: 'Detail',
          selected: false,
          link: `${DASHBOARD_ROUTES.EDIT_TASK_LIST}`.replace(
            ':templateTaskListId',
            `${templateTaskList?.id}`,
          ),
        },
        {
          title: 'Template Task',
          selected: false,
          link: `${DASHBOARD_ROUTES.LIST_TASK}`.replace(
            ':templateTaskListId',
            `${templateTaskList?.id}`,
          ),
        },
        {
          title: 'Create Template Task',
          selected: true,
          link: `${DASHBOARD_ROUTES.NEW_TASK}`.replace(
            ':templateTaskListId',
            `${templateTaskList?.id}`,
          ),
        },
      ];
  if (isloading) return null;
  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <Box sx={{ marginTop: '7px' }}>
        <div className="nv-container">
          <Outlet context={{ templateTask }} />
        </div>
      </Box>
    </>
  );
};
