/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type TDatePickerProps = MuiDatePickerProps<any> & {
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  error?: boolean;
  helperText?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
  inputProps?: string;
};

const CustomDatePicker = ({
  error,
  helperText,
  required,
  fullWidth,
  inputProps,
  ...props
}: TDatePickerProps) => {
  const [focus, setFocus] = useState(false);
  const [shink, setShink] = useState(false);

  return (
    <MuiDatePicker
      slots={{
        openPickerIcon: ArrowDropDownIcon,
      }}
      slotProps={{
        textField: {
          fullWidth,
          placeholder: '',
          required: required,
          InputLabelProps: {
            shrink: props.value ? true : shink,
            sx: {
              color: '#213B54',
              fontWeight: 'bold',
              fontSize: '14px',
              top: props.value ? '0px' : focus ? '0px' : '-10px',
              display: 'block',
            },
          },
          InputProps: {
            className: inputProps
              ? inputProps
              : '!text-sm !h-[32px] !w-[148px] !rounded-[10px]',
            onFocus: () => {
              setFocus(true);
              setShink(true);
            },
            onBlur: () => {
              setFocus(false);
              setShink(false);
            },
          },
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#213B54 !important',
            },
            input: {
              color: '#213B54',
              fontSize: '12px',
              fontWeight: 700,
              ': hover': {
                cursor: props.disabled ? 'not-allowed' : '',
              },
            },
            '& .MuiSvgIcon-root': {
              color: '#1D1B20',
            },
            '& .MuiButtonBase-root': {
              paddingRight: '1px',
              paddingLeft: '0px',
              height: '24px',
            },
            label: {
              maxWidth: 'calc(100% - 36px)',
            },
          },
          error,
          helperText,
        },
      }}
      {...props}
    />
  );
};

const DatePickerTracking = ({
  control,
  name,
  rules = {},
  ...props
}: TDatePickerProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, value, ...restField }, fieldState }) => {
          const convertedValue = !value ? null : moment(value || moment());
          return (
            <CustomDatePicker
              {...restField}
              inputRef={ref}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...props}
              value={convertedValue}
            />
          );
        }}
      />
    );
  }
  return <CustomDatePicker {...props} />;
};

export default DatePickerTracking;
